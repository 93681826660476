import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/rising-mortgage-rates-assumable-loans");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Rising Mortgage Rates Make Assumable Loans More Appealing.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                It’s no secret that mortgage rates fluctuate and that, lately, they’ve been
                climbing. But as real estate demand remains strong, both buyers and sellers are
                looking for creative ways to achieve their goals. One increasingly attractive option
                in today’s climate? Assumable loans. If you’re considering buying or selling a home,
                this blog all about assumable loans is one you won’t want to miss.
              </CommonParagraph>
              <Heading type="h2">The Current State of San Diego’s Market</Heading>
              <CommonParagraph>
                What with the thriving job market, desirable neighborhoods, and proximity to the
                ocean, it’s easy to see why San Diego’s real estate market remains competitive. That
                said, rising loan mortgage rates have slowed some buyers' ability to enter the
                market.
              </CommonParagraph>
              <CommonParagraph>
                <BlogLink
                  url="https://selbysellssd.com/blog/affordable-living-san-diego-budget"
                  text="Affordability"
                />{" "}
                is a top concern for many, and in this environment, assumable loans may offer buyers
                a way to secure more competitive interest rates while also giving sellers another
                way to make their property stand out.
              </CommonParagraph>
              <Heading type="h2">What’s an Assumable Mortgage Loan?</Heading>
              <CommonParagraph>
                An assumable loan is a type of mortgage that allows a buyer to take over the
                seller’s existing loan—including its terms and interest rate. Because mortgage rates
                today are higher than they have been in years past, it’s clear how this can be an
                attractive option. Unlike a traditional loan, where buyers must secure new financing
                at current rates, assumable loans let those buyers benefit from the low rates locked
                in by sellers.
              </CommonParagraph>
              <Heading type="h2">How Do Assumable Loans Work?</Heading>
              <CommonParagraph>
                Let’s say you’re{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="buying a home"
                />{" "}
                with an assumable loan. You’ll work with the seller’s lender to transfer the loan
                into your name. Just as if you were to get your own financing, the lender will
                assess your creditworthiness and financial stability.
              </CommonParagraph>
              <CommonParagraph>
                If approved, you’d assume the remaining balance, interest rate, and payment schedule
                of the seller’s mortgage. Typically, you’d need to make up the difference between
                the loan balance and the home’s purchase price, either through a cash payment or
                secondary financing.
              </CommonParagraph>
              <Heading type="h2">Are All Home Loans Assumable?</Heading>
              <CommonParagraph>
                No, not all loans are assumable, so if you’re looking for this kind of opportunity,
                it’s important to know the types of home loans that are assumable.
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>VA loans:</strong> VA loans are assumable but are usually limited to
                  qualified service members or veterans, which means they’re not an option for
                  everyone.
                </li>
                <li>
                  <strong>FHA loans:</strong> FHA loans are popular and have the added benefit of
                  typically being assumable.
                </li>
                <li>
                  <strong>Conventional loans:</strong> Unless specified in the terms, most
                  conventional loans are not assumable.
                </li>
              </ul>
              <Heading type="h2">Pros and Cons of an Assumable Mortgage Loan</Heading>
              <CommonParagraph>
                As is true with any financing option, assumable loans have advantages and potential
                drawbacks.
              </CommonParagraph>
              <Heading type="h3">Pros</Heading>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Potentially lower interest rates:</strong> This is a great perk for
                  buyers, as they can benefit from rates secured during a lower-interest period. A
                  low rate could potentially save thousands over the life of the loan.
                </li>
                <li>
                  <strong>Lower closing costs:</strong> It’s possible that an assumable loan could
                  have fewer fees compared to a new one.
                </li>
                <li>
                  <strong>Increased appeal:</strong> An assumable loan is a strong selling point,
                  especially in high-rate environments, and could help{" "}
                  <BlogLink
                    url="https://selbysellssd.com/sell-your-san-diego-home/"
                    text="sellers attract more buyers"
                  />
                  .
                </li>
              </ul>
              <Heading type="h3">Cons</Heading>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Limited loan types:</strong> As we briefly mentioned, not all loans are
                  assumable.
                </li>
                <li>
                  <strong>Lender approval:</strong> Buyers will still need to gain lender approval,
                  which means the potential to be turned down.
                </li>
                <li>
                  <strong>Down payment requirements:</strong> Because buyers need to cover the
                  difference between the home’s sales price and the remaining loan balance, they may
                  need to make a large cash payment.
                </li>
              </ul>
              <Heading type="h2">Navigate the Market With Help From The Selby Team</Heading>
              <CommonParagraph>
                We understand the complexities of today’s market and are here to guide you every
                step of the way, whether that means sharing our insights on financing or introducing
                you to{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/choosing-perfect-san-diego-neighborhood"
                  text="neighborhoods that align with your lifestyle"
                />
                . <ContactSlideoutLink text="Connect with us" /> to learn more about how assumable
                loans and other strategies can help you reach your real estate goals!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
